import { Input } from '../../input/Input';
import { isReadonlyField } from '../editorConfig';
import { DateFieldSchema, Field as FieldType } from '../../../types/Shared';

export const DateField = ({
  fieldSchema,
  passedValue,
  handleUpdate,
  documentType,
}: FieldType<DateFieldSchema, string>) => {
  /**
   * Returns true if the browser supports the specified date input type, typically 'month' or 'year'
   */
  function browserSupportsDateType(type: string): boolean {
    const input = document.createElement('input');
    input.setAttribute('type', type);
    return input.type == type;
  }

  /**
   * Returns an input type string based on date format
   */
  function resolveDateFormat(format: string): 'date' | 'month' {
    if ((format === 'YYYY-MM' || format === 'YYYY') && browserSupportsDateType('month')) return 'month';
    return 'date';
  }

  function formatDateValue(date: string | number, format: 'date' | 'month'): string | number {
    if (typeof date !== 'string') return date;

    const [year, month] = date.split('-');
    if (format === 'month') return `${year}-${month}`;
    return date;
  }

  const type = resolveDateFormat(fieldSchema.options.dateFormat);
  const formattedValue = formatDateValue(passedValue, type);

  return (
    <Input
      fullwidth
      id={fieldSchema.name}
      value={passedValue && formattedValue}
      onChange={(e) => handleUpdate(e.target.value)}
      type={type}
      disabled={isReadonlyField(fieldSchema.name, documentType)}
    />
  );
};

export default DateField;
