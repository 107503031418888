/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import uuid from 'react-uuid';
import { Button } from '../../input/Button';
import { colors } from '../../styles';
import { isReadonlyField } from '../editorConfig';
import {
  ArrayElement,
  ArrayFieldSchema,
  Field as FieldType,
  PassedArrayValue,
  PassedValue,
} from '../../../types/Shared';
import { SchemaInputField } from '../SchemaInputField';

export const ArrayField = ({
  fieldSchema,
  passedValue,
  handleUpdate,
  documentType,
}: FieldType<ArrayFieldSchema, PassedArrayValue>) => {
  const shiftFromTo = (fromPosition: number, toPosition: number, arr: any[]): any[] => {
    const bufferedArray = [...arr];
    bufferedArray.splice(fromPosition, 1); // remove entry from original position
    bufferedArray.splice(toPosition, 0, arr[fromPosition]); // Insert entry at new position
    return bufferedArray;
  };

  const addArrayItem = (type: string): void => {
    const typeKey = type === 'reference' ? '_type' : 'type';
    handleUpdate([...(passedValue || []), { [typeKey]: type, _key: uuid() }]);
  };

  const removeArrayItem = (key: string): void => {
    if (window.confirm('Remove item?')) {
      handleUpdate([...passedValue].filter((value) => value._key !== key));
    }
  };

  const moveItemUp = (i: number): void => {
    if (i > 0 || passedValue.length - 1 >= i) {
      // Shift the item at position i to position i-1
      handleUpdate(shiftFromTo(i, i - 1, passedValue));
    }
  };

  const moveItemDown = (i: number): void => {
    if (i > 1 || passedValue.length - 1 > i) {
      // Shift the item at position i to position i+1
      handleUpdate(shiftFromTo(i, i + 1, passedValue));
    }
  };

  return (
    <>
      <div>
        {Array.isArray(passedValue) &&
          passedValue?.map((pv, i) => (
            <div key={pv._key} style={{ marginBottom: '16px' }}>
              <SchemaInputField
                key={pv._key}
                fieldSchema={fieldSchema.of[0]}
                passedValue={pv}
                documentType={documentType}
                handleUpdate={(v) => {
                  const arrVal = [...passedValue];
                  arrVal[i] = v as PassedValue & ArrayElement;
                  handleUpdate(arrVal);
                }}
              />

              {isReadonlyField(fieldSchema.name, documentType) ? null : (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Button size="compact" variant="primary" onClick={() => removeArrayItem(pv._key)}>
                    Remove
                  </Button>
                  <Button size="compact" variant="primary" disabled={i <= 0} onClick={() => moveItemUp(i)}>
                    Move up
                  </Button>
                  <Button
                    size="compact"
                    variant="primary"
                    disabled={i >= passedValue.length - 1}
                    onClick={() => moveItemDown(i)}>
                    Move down
                  </Button>
                </div>
              )}
            </div>
          ))}
      </div>
      <div style={{ marginTop: '20px', paddingTop: '8px', borderTop: `1px solid ${colors.neutral3}` }}>
        {fieldSchema.of.map((o) => (
          <Button variant="primary" size="compact" key={o.type} onClick={() => addArrayItem(o.type)}>
            Add entry
          </Button>
        ))}
      </div>
    </>
  );
};

export default ArrayField;
