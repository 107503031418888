import * as msal from '@azure/msal-browser';

const msalConfig: msal.Configuration = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID || '',
    authority: import.meta.env.VITE_MSAL_AUTHORITY || '',
    redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI || '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const tokenRequest = {
  redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI || '',
  // this ID is Application (client) ID for the pnp app registration in 2S Azure.
  scopes: ['api://5b4c88f5-9967-4d8f-b034-b7d5bf4976cf/Read'],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { tokenRequest };
export default msalInstance;
