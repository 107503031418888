import { useState, ChangeEvent, ClipboardEvent } from 'react';
import styled from 'styled-components';
import { sanityConfig } from '../../../services/sanityConfig';
import { uploadAsset } from '../../../utils/api';
import { colors } from '../../styles';
import { Field as FieldType, ImageFieldSchema, PassedImageValue, PassedValue } from '../../../types/Shared';

// TODO: Cyclical dependency, get rid off
import { SchemaInputField } from '../SchemaInputField';

const ErrorContainer = styled.div`
  color: ${colors.secondary3};
`;

const ImageContainer = styled.div`
  border: 1px solid ${colors.neutral3};
  padding: 8px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    border: 1px solid ${colors.neutral2};
  }

  .image-scale-container {
    width: 140px;
    height: 140px;
    background: #eee;
    padding: 4px;
    border-radius: 2px;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }

  & ~ * {
    margin-top: 16px;
  }

  .hint-text {
    padding: 8px 0;
  }

  .upload-input {
    display: flex;
    font-size: inherit;
    background: ${colors.neutral3};
    border: none;
    padding: 8px 16px;
    max-width: 10em;
    cursor: pointer;
    margin-right: 16px;

    &:hover {
      background: ${colors.neutral2};
    }

    &::-webkit-file-upload-button {
      display: none;
      background: ${colors.neutral3};
      border: none;
      padding: 8px 16px;
    }
  }
`;

export const ImageField = ({
  passedValue,
  handleUpdate,
  fieldSchema,
  documentType,
}: FieldType<ImageFieldSchema, PassedImageValue>) => {
  const [assetPreview, setAssetPreview] = useState<string | ArrayBuffer | null>(null);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const sanityAssetUrl = (value: { asset: { _ref: string } }, params: string): string => {
    if (!value.asset?._ref?.split('-')) return '';

    const [, filename, size, filetype] = value.asset._ref.split('-');
    return `https://cdn.sanity.io/images/${sanityConfig.projectId}/${sanityConfig.dataset}/${filename}-${size}.${filetype}?${params}`;
  };

  const uploadImage = (file: Blob) => {
    setIsUploading(true);
    setError('');

    uploadAsset(file)
      .then((res: { data: { uploadId: string; name: string; _id: string } }) => {
        setIsUploading(false);

        handleUpdate({
          _type: 'image',
          _key: res.data.uploadId,
          name: res.data.name,
          asset: {
            _type: 'reference',
            _ref: res.data._id,
          },
        });
      })
      .catch((e: Error) => {
        setIsUploading(false);
        if (e) {
          setError(e.toString());
          console.error(e);
        }
      });
  };

  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    const file = e.clipboardData.files[0];
    if (file?.type?.includes('image')) uploadImage(file);
  };

  const createFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e && e.target && e.target.files && e.target.files.length ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = () => {
        const image = new Blob([new Uint8Array(reader.result as ArrayBuffer)], { type: file.type });
        const imageFile = new File([image], file.name);
        uploadImage(imageFile);
        setAssetPreview(reader.result);
      };
    }
  };

  if (isUploading) {
    return (
      <ImageContainer>
        <span className="hint-text">Image is uploading...</span>
      </ImageContainer>
    );
  }

  return (
    <>
      <ImageContainer tabIndex={0} onPaste={handlePaste} role="button">
        {passedValue?.asset?._ref && (
          <div className="image-scale-container">
            <img
              height="140"
              src={passedValue?.asset?._ref ? sanityAssetUrl(passedValue, 'h=140') : String(assetPreview)}
              alt=""
            />
          </div>
        )}
        {!passedValue?.asset?._ref && (
          <>
            <input className="upload-input" type="file" onChange={createFileUpload} accept="image/*" />
            <span className="hint-text">Hint: you can select this area and paste (cmd+v)</span>
          </>
        )}
      </ImageContainer>
      {!!error && <ErrorContainer>{error}</ErrorContainer>}
      {fieldSchema.fields?.map((field) => (
        <SchemaInputField
          key={`key-${field.name}`}
          passedValue={field && field.name ? (passedValue[field.name] as PassedValue) : ''}
          fieldSchema={field}
          documentType={documentType}
          handleUpdate={(value): void => {
            handleUpdate({
              ...passedValue,
              [field.name]: value,
            });
          }}
        />
      ))}
    </>
  );
};

export default ImageField;
