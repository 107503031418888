import { useContext, useEffect, useState, ChangeEvent } from 'react';
import LanguageContext from '../../../contexts/LanguageContext';
import { getReferencesOfType } from '../../../utils/api';
import { Select } from '../../input/Input';
import { isReadonlyField } from '../editorConfig';
import Employee from '../../../types/Employee';
import {
  Approach,
  Field as FieldType,
  FieldSchema,
  PassedReferenceValue,
  ReferenceFieldSchema,
  PassedValue,
} from '../../../types/Shared';

type OptionsType = {
  _id: string;
  [key: string]: PassedValue;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  name: {
    _type: string;
    [key: string]: PassedValue;
  };
};

export const ReferenceField = ({
  fieldSchema,
  passedValue,
  handleUpdate,
  documentType,
}: FieldType<ReferenceFieldSchema, PassedReferenceValue>) => {
  const [options, setOptions] = useState<OptionsType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { currentLanguage } = useContext(LanguageContext);

  const updateRefId = (refId: string) => {
    handleUpdate({ ...passedValue, _ref: refId });
  };

  useEffect(() => {
    const getNameField = (f: Employee | Approach | FieldSchema): string => {
      if ((f as Employee).firstName) {
        return String((f as Employee).firstName);
      }
      if ((f as Approach).name?._type === 'localeString') {
        return String((f as Approach).name[currentLanguage]);
      }
      return String(f.name);
    };
    const fetchReferenceTypes = async () => {
      setLoading(true);
      const refObjects = await getReferencesOfType(fieldSchema.to[0].type);
      setOptions(
        refObjects && Array.isArray(refObjects)
          ? refObjects.sort((a, b) => {
              const nameA = getNameField(a as unknown as Employee | Approach | FieldSchema)
                ? getNameField(a as unknown as Employee | Approach | FieldSchema).toUpperCase()
                : '';
              const nameB = getNameField(a as unknown as Employee | Approach | FieldSchema)
                ? getNameField(b as unknown as Employee | Approach | FieldSchema).toUpperCase()
                : '';
              return nameA > nameB ? 1 : -1;
            })
          : [],
      );
      setLoading(false);
    };
    if (options.length === 0) {
      fetchReferenceTypes();
    }
  }, [fieldSchema, currentLanguage]);

  return (
    <Select
      disabled={isReadonlyField(fieldSchema.name, documentType)}
      fullwidth
      value={loading || !passedValue?._ref ? 'none' : passedValue?._ref}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => updateRefId(e.target.value)}
      style={{ flex: '1' }}>
      <option value="none" disabled>
        {loading ? 'Loading...' : 'Select...'}
      </option>
      {options.map((opt) => {
        let value = '';
        if (opt.name?._type === 'localeString') {
          value = String(opt.name[currentLanguage]);
        } else if (opt.firstName && opt.lastName) {
          value = `${String(opt.firstName)} ${String(opt.lastName)}`;
        } else if (opt.name) {
          value = String(opt.name);
        }

        return (
          <option key={opt._id} value={opt._id}>
            {value}
          </option>
        );
      })}
    </Select>
  );
};

export default ReferenceField;
