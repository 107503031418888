import { BooleanFieldSchema, Field as FieldType } from '../../../types/Shared';
import { CheckLabel } from '../../input/Input';
import { isReadonlyField } from '../editorConfig';

export const BooleanField = ({
  passedValue,
  handleUpdate,
  fieldSchema,
  documentType,
}: FieldType<BooleanFieldSchema, boolean>) => {
  return (
    <CheckLabel htmlFor={fieldSchema.name} className={passedValue ? 'checked' : ''}>
      <input
        disabled={isReadonlyField(fieldSchema.name, documentType)}
        id={fieldSchema.name}
        type="checkbox"
        checked={passedValue}
        onChange={(e) => {
          handleUpdate(Boolean(e.target.checked));
        }}
        style={{ marginRight: '8px' }}
      />
      {fieldSchema.title}
    </CheckLabel>
  );
};

export default BooleanField;
