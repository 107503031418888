/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { FieldSection } from '../input/Input';
import { Heading3 } from '../Typography';
import { isHiddenField, isReadonlyField } from './editorConfig';
import {
  FieldSchema,
  PassedArrayValue,
  PassedImageValue,
  PassedLocaleBlockValue,
  PassedLocaleStringValue,
  PassedObjectValue,
  PassedReferenceValue,
  PassedValue,
} from '../../types/Shared';
import {
  BooleanField,
  LocaleStringField,
  TextField,
  NumberField,
  DateField,
  ImageField,
  ReferenceField,
  LocaleBlockField,
  ObjectField,
  ArrayField,
} from './inputFields';

export interface FieldResolver {
  fieldSchema: FieldSchema;
  passedValue: PassedValue;
  handleUpdate: (value: unknown) => void;
  documentType: string;
}

export const SchemaInputField = ({ fieldSchema, passedValue, handleUpdate, documentType }: FieldResolver) => {
  if (isHiddenField(fieldSchema.name, documentType)) return null;

  return (
    <FieldSection key={fieldSchema.name}>
      {(fieldSchema.title || fieldSchema.name) && (
        <Heading3 className="label-text" id={fieldSchema.title ?? fieldSchema.name}>
          {fieldSchema.title || fieldSchema.name}
          {isReadonlyField(fieldSchema.name, documentType) && '(not editable)'}
        </Heading3>
      )}
      {fieldSchema.description && <p style={{ whiteSpace: 'pre-line' }}>{fieldSchema.description}</p>}

      <Field {...{ fieldSchema, passedValue, handleUpdate, documentType }} />
    </FieldSection>
  );
};

const Field = ({ fieldSchema, passedValue, handleUpdate, documentType }: FieldResolver) => {
  switch (fieldSchema.type) {
    case 'boolean':
      return (
        <BooleanField
          {...{ fieldSchema, passedValue: passedValue as unknown as boolean, handleUpdate, documentType }}
        />
      );

    case 'string':
      return <TextField {...{ fieldSchema, passedValue: passedValue as string, handleUpdate, documentType }} />;

    case 'number':
      return <NumberField {...{ fieldSchema, passedValue: passedValue as number, handleUpdate, documentType }} />;

    case 'date':
      return <DateField {...{ fieldSchema, passedValue: passedValue as string, handleUpdate, documentType }} />;

    case 'image':
      return (
        <ImageField {...{ fieldSchema, passedValue: passedValue as PassedImageValue, handleUpdate, documentType }} />
      );

    case 'localeBlock':
      return (
        <LocaleBlockField
          {...{
            fieldSchema,
            passedValue: passedValue as unknown as PassedLocaleBlockValue,
            handleUpdate,
            documentType,
          }}
        />
      );

    case 'localeString':
      return (
        <LocaleStringField
          {...{
            fieldSchema,
            passedValue: passedValue as unknown as PassedLocaleStringValue,
            handleUpdate,
            documentType,
          }}
        />
      );

    case 'array':
      return (
        <ArrayField {...{ fieldSchema, passedValue: passedValue as PassedArrayValue, handleUpdate, documentType }} />
      );

    case 'object':
      return (
        <ObjectField {...{ fieldSchema, passedValue: passedValue as PassedObjectValue, handleUpdate, documentType }} />
      );

    case 'reference':
      return (
        <ReferenceField
          {...{ fieldSchema, passedValue: passedValue as unknown as PassedReferenceValue, handleUpdate, documentType }}
        />
      );

    default:
      return <p>No matching field for schema</p>;
  }
};
