/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Field as FieldType, FieldSchema, ObjectFieldSchema, PassedObjectValue } from '../../../types/Shared';
import { SchemaInputField } from '../SchemaInputField';

export const ObjectField = ({
  fieldSchema,
  passedValue,
  handleUpdate,
  documentType,
}: FieldType<ObjectFieldSchema, PassedObjectValue>) => {
  if (!fieldSchema.fields) {
    return <p>No fields in object</p>;
  }
  return (
    <>
      {fieldSchema.fields?.map((field: FieldSchema) => {
        return (
          <SchemaInputField
            key={field.name}
            fieldSchema={field}
            passedValue={passedValue?.[field.name]}
            documentType={documentType}
            handleUpdate={(v) => handleUpdate({ ...passedValue, [field.name]: v })}
          />
        );
      })}
    </>
  );
};

export default ObjectField;
