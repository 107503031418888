import { Input } from '../../input/Input';
import { isReadonlyField } from '../editorConfig';
import { Field as FieldType, NumberFieldSchema } from '../../../types/Shared';

export const NumberField = ({
  fieldSchema,
  passedValue,
  handleUpdate,
  documentType,
}: FieldType<NumberFieldSchema, number>) => {
  return (
    <Input
      fullwidth
      id={fieldSchema.name}
      value={passedValue}
      onChange={(e) => handleUpdate(Number(e.target.value))}
      type="number"
      disabled={isReadonlyField(fieldSchema.name, documentType)}
    />
  );
};

export default NumberField;
