export const IconEnglish = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <circle cx="16" cy="16" r="16" fill="#F95738" />
      </mask>
      <g mask="url(#mask0)">
        <circle cx="16" cy="16" r="16" fill="#112B5A" />
        <mask
          id="path-3-outside-1"
          maskUnits="userSpaceOnUse"
          x="-13.2843"
          y="-13.2841"
          width="56.5685"
          height="56.5685"
          fill="black">
          <rect fill="white" x="-13.2843" y="-13.2841" width="56.5685" height="56.5685" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9999 16.5859L28.4049 28.9909L29.112 28.2838L16.707 15.8788L29.0207 3.56511L28.3136 2.858L15.9999 15.1717L1.27203 0.443787L0.564927 1.15089L15.2928 15.8788L2.85779 28.3138L3.56489 29.0209L15.9999 16.5859Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9999 16.5859L28.4049 28.9909L29.112 28.2838L16.707 15.8788L29.0207 3.56511L28.3136 2.858L15.9999 15.1717L1.27203 0.443787L0.564927 1.15089L15.2928 15.8788L2.85779 28.3138L3.56489 29.0209L15.9999 16.5859Z"
          fill="#F95738"
        />
        <path
          d="M28.4049 28.9909L27.6978 29.698L28.4049 30.4051L29.112 29.698L28.4049 28.9909ZM15.9999 16.5859L16.707 15.8788L15.9999 15.1717L15.2928 15.8788L15.9999 16.5859ZM29.112 28.2838L29.8191 28.9909L30.5262 28.2838L29.8191 27.5767L29.112 28.2838ZM16.707 15.8788L15.9999 15.1717L15.2928 15.8788L15.9999 16.5859L16.707 15.8788ZM29.0207 3.56511L29.7278 4.27221L30.435 3.56511L29.7278 2.858L29.0207 3.56511ZM28.3136 2.858L29.0207 2.15089L28.3136 1.44379L27.6065 2.15089L28.3136 2.858ZM15.9999 15.1717L15.2928 15.8788L15.9999 16.5859L16.707 15.8788L15.9999 15.1717ZM1.27203 0.443787L1.97914 -0.26332L1.27203 -0.970427L0.564927 -0.26332L1.27203 0.443787ZM0.564927 1.15089L-0.14218 0.443787L-0.849287 1.15089L-0.14218 1.858L0.564927 1.15089ZM15.2928 15.8788L15.9999 16.5859L16.707 15.8788L15.9999 15.1717L15.2928 15.8788ZM2.85779 28.3138L2.15068 27.6067L1.44357 28.3138L2.15068 29.0209L2.85779 28.3138ZM3.56489 29.0209L2.85779 29.7281L3.56489 30.4352L4.272 29.7281L3.56489 29.0209ZM29.112 28.2838L16.707 15.8788L15.2928 17.293L27.6978 29.698L29.112 28.2838ZM28.4049 27.5767L27.6978 28.2838L29.112 29.698L29.8191 28.9909L28.4049 27.5767ZM15.9999 16.5859L28.4049 28.9909L29.8191 27.5767L17.4142 15.1717L15.9999 16.5859ZM28.3136 2.858L15.9999 15.1717L17.4142 16.5859L29.7278 4.27221L28.3136 2.858ZM27.6065 3.56511L28.3136 4.27221L29.7278 2.858L29.0207 2.15089L27.6065 3.56511ZM16.707 15.8788L29.0207 3.56511L27.6065 2.15089L15.2928 14.4646L16.707 15.8788ZM0.564926 1.15089L15.2928 15.8788L16.707 14.4646L1.97914 -0.26332L0.564926 1.15089ZM1.27203 1.858L1.97914 1.15089L0.564927 -0.26332L-0.14218 0.443787L1.27203 1.858ZM15.9999 15.1717L1.27203 0.443787L-0.14218 1.858L14.5857 16.5859L15.9999 15.1717ZM3.56489 29.0209L15.9999 16.5859L14.5857 15.1717L2.15068 27.6067L3.56489 29.0209ZM4.272 28.3138L3.56489 27.6067L2.15068 29.0209L2.85779 29.7281L4.272 28.3138ZM15.2928 15.8788L2.85779 28.3138L4.272 29.7281L16.707 17.293L15.2928 15.8788Z"
          fill="white"
          mask="url(#path-3-outside-1)"
        />
        <mask id="path-5-outside-2" maskUnits="userSpaceOnUse" x="-4" y="-6" width="40" height="44" fill="black">
          <rect fill="white" x="-4" y="-6" width="40" height="44" />
          <path fillRule="evenodd" clipRule="evenodd" d="M14 18V36H18V18H34V14H18V-4H14V14H-2V18H14Z" />
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M14 18V36H18V18H34V14H18V-4H14V14H-2V18H14Z" fill="#F95738" />
        <path
          d="M14 36H12.5V37.5H14V36ZM14 18H15.5V16.5H14V18ZM18 36V37.5H19.5V36H18ZM18 18V16.5H16.5V18H18ZM34 18V19.5H35.5V18H34ZM34 14H35.5V12.5H34V14ZM18 14H16.5V15.5H18V14ZM18 -4H19.5V-5.5H18V-4ZM14 -4V-5.5H12.5V-4H14ZM14 14V15.5H15.5V14H14ZM-2 14V12.5H-3.5V14H-2ZM-2 18H-3.5V19.5H-2V18ZM15.5 36V18H12.5V36H15.5ZM18 34.5H14V37.5H18V34.5ZM16.5 18V36H19.5V18H16.5ZM34 16.5H18V19.5H34V16.5ZM32.5 14V18H35.5V14H32.5ZM18 15.5H34V12.5H18V15.5ZM16.5 -4V14H19.5V-4H16.5ZM14 -2.5H18V-5.5H14V-2.5ZM15.5 14V-4H12.5V14H15.5ZM-2 15.5H14V12.5H-2V15.5ZM-0.5 18V14H-3.5V18H-0.5ZM14 16.5H-2V19.5H14V16.5Z"
          fill="white"
          mask="url(#path-5-outside-2)"
        />
      </g>
    </svg>
  );
};

export const IconNorwegian = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <circle cx="16" cy="16" r="16" fill="#F95738" />
      </mask>
      <g mask="url(#mask0)">
        <circle cx="16" cy="16" r="16" fill="#F95738" />
        <mask id="path-4-outside-1" maskUnits="userSpaceOnUse" x="-4" y="-6" width="40" height="44" fill="black">
          <rect fill="white" x="-4" y="-6" width="40" height="44" />
          <path fillRule="evenodd" clipRule="evenodd" d="M10 18L10 36H14L14 18H34V14H14L14 -4H10L10 14H-2V18H10Z" />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 18L10 36H14L14 18H34V14H14L14 -4H10L10 14H-2V18H10Z"
          fill="#112B5A"
        />
        <path
          d="M10 36H8.5V37.5H10V36ZM10 18H11.5V16.5H10V18ZM14 36V37.5H15.5V36H14ZM14 18V16.5H12.5V18H14ZM34 18V19.5H35.5V18H34ZM34 14H35.5V12.5H34V14ZM14 14H12.5V15.5H14V14ZM14 -4L15.5 -4V-5.5H14V-4ZM10 -4V-5.5H8.5V-4L10 -4ZM10 14V15.5H11.5V14H10ZM-2 14V12.5H-3.5V14H-2ZM-2 18H-3.5V19.5H-2V18ZM11.5 36L11.5 18H8.5L8.5 36H11.5ZM14 34.5H10V37.5H14V34.5ZM12.5 18L12.5 36H15.5V18H12.5ZM34 16.5H14V19.5H34V16.5ZM32.5 14V18H35.5V14H32.5ZM14 15.5H34V12.5H14V15.5ZM12.5 -4L12.5 14H15.5L15.5 -4L12.5 -4ZM10 -2.5H14V-5.5H10V-2.5ZM11.5 14L11.5 -4L8.5 -4L8.5 14H11.5ZM-2 15.5H10V12.5H-2V15.5ZM-0.5 18V14H-3.5V18H-0.5ZM10 16.5H-2V19.5H10V16.5Z"
          fill="white"
          mask="url(#path-4-outside-1)"
        />
      </g>
    </svg>
  );
};
