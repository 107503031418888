import { ChangeEvent } from 'react';

import { Input, Select } from '../../input/Input';
import { isReadonlyField } from '../editorConfig';
import { Field as FieldType, StringFieldSchema } from '../../../types/Shared';

export const TextField = ({
  fieldSchema,
  passedValue,
  handleUpdate,
  documentType,
}: FieldType<StringFieldSchema, string>) => {
  return (
    <>
      {/* Render a text input or a dropdown */}
      {(fieldSchema.options?.list && (
        <Select
          fullwidth
          name={fieldSchema.name}
          value={passedValue}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => handleUpdate(e.target.value)}>
          {fieldSchema.options.list.map((listItem) => (
            <option key={listItem}>{listItem}</option>
          ))}
        </Select>
      )) || (
        <Input
          fullwidth
          id={fieldSchema.name}
          value={passedValue}
          onChange={(e) => handleUpdate(e.target.value)}
          type="text"
          disabled={isReadonlyField(fieldSchema.name, documentType)}
        />
      )}
    </>
  );
};

export default TextField;
