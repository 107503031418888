import { useContext, useState } from 'react';
import LanguageContext from '../../../contexts/LanguageContext';
import { Input } from '../../input/Input';
import { ExpandedLanguages, LanguageIndicator, LanguageLabel } from '../LanguageSelectorTools';
import { isReadonlyField } from '../editorConfig';
import { ShowLanguagesButton } from '../ShowLanguagesButton';
import { Field as FieldType, LocaleStringFieldSchema, PassedLocaleStringValue } from '../../../types/Shared';

export const LocaleStringField = ({
  passedValue,
  handleUpdate,
  fieldSchema,
  documentType,
}: FieldType<LocaleStringFieldSchema, PassedLocaleStringValue>) => {
  const { currentLanguage, availableLanguages } = useContext(LanguageContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <LanguageLabel>
        <LanguageIndicator language={currentLanguage} />
        <Input
          disabled={isReadonlyField(fieldSchema.name, documentType)}
          fullwidth
          value={passedValue?.[currentLanguage]}
          style={{ paddingRight: '3.5em' }}
          onChange={(e) => handleUpdate({ ...passedValue, [currentLanguage]: e.target.value })}
        />
      </LanguageLabel>

      <ShowLanguagesButton expanded={expanded} setExpanded={setExpanded}></ShowLanguagesButton>

      {expanded && (
        <ExpandedLanguages>
          {Object.values(availableLanguages)
            .filter((language) => language !== currentLanguage)
            .map((language) => (
              <LanguageLabel key={language}>
                <LanguageIndicator language={language} />

                <Input
                  disabled={isReadonlyField(fieldSchema.name, documentType)}
                  style={{ display: 'flex', width: '100%', paddingRight: '3.5em' }}
                  value={passedValue?.[language]}
                  onChange={(e) => handleUpdate({ ...passedValue, [language]: e.target.value })}
                />
              </LanguageLabel>
            ))}
        </ExpandedLanguages>
      )}
    </>
  );
};

export default LocaleStringField;
